import Col from "react-bootstrap/Col"
import PropTypes from "prop-types"
import React from "react"
import Row from "react-bootstrap/Row"

export default function Brands({ children, brandText }) {
  /*const images = imgSources.map((src)=>
  <img key={src} src={src}
                  style={{
                    maxWidth: "300px",
                    height: "auto",
                    opacity: ".5",
                    margin: "4px",
                    filter: "grayscale(100%)",
                  }}
                />
  );*/
  return (
    <Row>
      <Col className="text-center my-5 text-muted" style={{ fontSize: 20 }}>
        {brandText && <p className="mb-3">{brandText}</p>}
        <div className="mb-4 justify-content-center align-items-center d-block d-lg-flex flex-row flex-nowrap">
          {children}
        </div>
      </Col>
    </Row>
  )
}

Brands.propTypes = {
  children: PropTypes.node,
  brandText: PropTypes.string,
}
