import React, { useEffect } from "react"
import {
  faLightbulb,
  faMoneyBill,
  faSave,
} from "@fortawesome/free-solid-svg-icons"

import Brands from "components/program/brands"
import Col from "react-bootstrap/Col"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Img from "gatsby-image"
import Layout from "components/layout"
import LocalizedLink from "components/localized-link"
import PropTypes from "prop-types"
import Row from "react-bootstrap/Row"
import SEO from "components/seo"
import Section from "components/section"
import { UpdatePWAOnIOS } from "utils/common"
import { graphql } from "gatsby"
import styles from "./index.module.scss"
import svgCekilis from "images/homepage/cekilis.svg"
import svgDestek from "images/homepage/destek.svg"
import svgGuvenlik from "images/homepage/guvenlik.svg"
import svgHomepageTop from "images/homepage/homepage-top.svg"
import svgMemnuniyet from "images/homepage/memnuniyet.svg"
import svgTasarim from "images/homepage/tasarim.svg"
import useTranslations from "components/use-translations"

export default function Home({ data }) {
  useEffect(() => UpdatePWAOnIOS(), [])
  const {
    index: {
      seoTitle,
      seoDescription,
      isinizi,
      kolaylastiriyoruz,
      ustAciklama,
      anindaDestek,
      anindaDestekMetin,
      uyumluTasarim,
      uyumluTasarimMetin,
      guvenlik,
      guvenlikMetin,
      memnuniyet,
      memnuniyetMetin,
      kutuphaneAltMetin,
      cekilisSiteAltMetin,
      ucretsizCekilisYapin,
      ucretsizCekilisYapinMetin,
      kaydedinVePaylasin,
      kaydedinVePaylasinMetin,
      deneyimleyin,
      deneyimleyinMetin,
      sizeOzelProjeniziKonusalim,
    },
    genel: {
      iletisim,
      hakkimizda,
      referanslarimizdanBazilari,
      iletisimeGec,
      kutuphaneProgrami,
      cekilisSitesi,
      ucretsizDene,
      detaySayfasi,
      hemenDene,
    },
  } = useTranslations()
  return (
    <Layout>
      <div className={styles.homepage}>
        <SEO
          title={seoTitle}
          description={seoDescription}
          pathname="https://www.kodvizit.com/"
          datePublished="2015-07-01T18:55:41+00:00"
          dateModified="2020-07-17T16:53:13+00:00"
          breadcrumbList={[]}
        />

        <Section
          divStyle={{
            backgroundImage:
              "radial-gradient(70% 70% at 50% 100%,#e8e8e8 0,#fafafa 100%)",
          }}
          containerStyle={{ paddingTop: 100, paddingBottom: 0 }}
        >
          <Row className="mt-0 mt-md-5">
            <Col lg="6" className="pr-lg-5">
              <h1 className={`text-lg-left text-center`}>
                {isinizi}.
                <br />
                {kolaylastiriyoruz}.
              </h1>
              <div
                className="mt-4 text-lg-left text-center "
                style={{
                  fontWeight: 400,
                  fontSize: "1.2rem",
                  lineHeight: "1.9rem",
                }}
              >
                {ustAciklama}
              </div>
              <Row className="mt-4">
                <Col>
                  <LocalizedLink
                    to="/hakkimizda/"
                    role="button"
                    className="w-100 shadow btn btn-primary d-flex justify-content-center align-items-center"
                    style={{ borderRadius: 7, height: 50 }}
                    variant="primary"
                  >
                    {hakkimizda}
                  </LocalizedLink>
                </Col>
                <Col>
                  <LocalizedLink
                    to="/iletisim/"
                    role="button"
                    className="w-100 shadow text-black btn btn-light d-flex justify-content-center align-items-center"
                    style={{ borderRadius: 7, height: 50 }}
                    variant="light"
                  >
                    {iletisim}
                  </LocalizedLink>
                </Col> 
              </Row>
            </Col>
            <Col lg="6">
              <img
                className="mt-4 mt-md-0"
                src={svgHomepageTop}
                alt={`${isinizi} ${kolaylastiriyoruz}`}
              />
            </Col>
          </Row>
          <div className="d-none d-md-block">
            <Row className="mt-2">
              <Col className="text-center mt-4">
                <span
                  className="font-weight-bold"
                  style={{ letterSpacing: "0.09rem", color: "#bbb" }}
                >
                  {referanslarimizdanBazilari}
                </span>
              </Col>
            </Row>

            <Brands>
              <Img
                className="m-2"
                style={{ opacity: 0.5 }}
                fixed={data.referans1.childImageSharp.fixed}
              ></Img>
              <Img
                className="m-2"
                style={{ opacity: 0.5 }}
                fixed={data.referans2.childImageSharp.fixed}
              ></Img>
              <Img
                className="m-2"
                style={{ opacity: 0.5 }}
                fixed={data.referans3.childImageSharp.fixed}
              ></Img>
            </Brands>
          </div>
          <div className="pb-3"></div>
        </Section>
        <Section bgLight={true}>
          <div className={styles.highlightContainer}>
            <div className={styles.highlight}>
              <img
                className=""
                alt={anindaDestek}
                src={svgDestek}
                style={{ height: 80 }}
              />
              <div
                style={{
                  fontSize: "1.6rem",
                  wordWrap: "normal",
                  fontWeight: 700,
                  letterSpacing: "-0.049rem",
                  lineHeight: "3.9rem",
                }}
                className="font-weight-bold mb-2"
              >
                {anindaDestek}
              </div>
              <div
                className="text-black"
                style={{ fontSize: "1rem", letterSpacing: "0.03rem" }}
              >
                {anindaDestekMetin}
              </div>
            </div>
            <div className={styles.highlight}>
              <img
                alt={uyumluTasarim}
                src={svgTasarim}
                style={{ height: 80 }}
              />
              <div
                style={{
                  fontSize: "1.6rem",
                  wordWrap: "normal",
                  fontWeight: 700,
                  letterSpacing: "-0.049rem",
                  lineHeight: "3.9rem",
                }}
                className="font-weight-bold mb-2"
              >
                {uyumluTasarim}
              </div>
              <div
                className="text-black"
                style={{ fontSize: "1rem", letterSpacing: "0.03rem" }}
              >
                {uyumluTasarimMetin}
              </div>
            </div>
            <div className={styles.highlight}>
              <img alt={guvenlik} src={svgGuvenlik} style={{ height: 80 }} />
              <div
                style={{
                  fontSize: "1.6rem",
                  wordWrap: "normal",
                  fontWeight: 700,
                  letterSpacing: "-0.049rem",
                  lineHeight: "3.9rem",
                }}
                className="font-weight-bold mb-2"
              >
                {guvenlik}
              </div>
              <div
                className="text-black"
                style={{ fontSize: "1rem", letterSpacing: "0.03rem" }}
              >
                {guvenlikMetin}
              </div>
            </div>
            <div className={styles.highlight}>
              <img
                alt={memnuniyet}
                src={svgMemnuniyet}
                style={{ height: 80 }}
              />
              <div
                style={{
                  fontSize: "1.6rem",
                  wordWrap: "normal",
                  fontWeight: 700,
                  letterSpacing: "-0.049rem",
                  lineHeight: "3.9rem",
                }}
                className="font-weight-bold mb-2"
              >
                {memnuniyet}
              </div>
              <div
                className="text-black"
                style={{ fontSize: "1rem", letterSpacing: "0.03rem" }}
              >
                {memnuniyetMetin}
              </div>
            </div>
          </div>
        </Section>
        <Section containerStyle={{ paddingBottom: 0 }}>
          <div
            className={`text-center ${styles.productHeader}`}
            style={{
              wordWrap: "normal",
              fontWeight: 700,
              letterSpacing: "-0.049rem",
              lineHeight: "3.9rem",
            }}
          >
            {kutuphaneProgrami}
          </div>
          <div
            style={{
              fontSize: "1.4rem",
              wordWrap: "normal",
              fontWeight: 600,
            }}
            className={`text-center text-black mt-4 ${styles.productHeader}`}
          >
            {kutuphaneAltMetin}
          </div>
          <div className="text-center mt-5 d-flex flex-row justify-content-center">
            <LocalizedLink
              to="kutuphane-programi/indir"
              role="button"
              className="shadow text-light btn btn-primary  d-flex justify-content-center align-items-center mx-1"
              style={{ borderRadius: 7, height: 50, width: 200 }}
            >
              {ucretsizDene}
            </LocalizedLink>

            <LocalizedLink
              to="kutuphane-programi"
              role="button"
              className="shadow text-black btn btn-light  d-flex justify-content-center align-items-center mx-1"
              style={{ borderRadius: 7, height: 50, width: 200 }}
            >
              {detaySayfasi}
            </LocalizedLink>
          </div>
          <div className="text-center" style={{ marginTop: 90 }}>
            <Img fluid={data.kutuphaneBilgisayar.childImageSharp.fluid} />
          </div>
        </Section>
        <Section bgLight={true}>
          <div
            className={`text-center ${styles.productHeader}`}
            style={{
              wordWrap: "normal",
              fontWeight: 700,
              letterSpacing: "-0.049rem",
              lineHeight: "3.9rem",
            }}
          >
            {cekilisSitesi}
          </div>
          <div
            style={{
              fontSize: "1.4rem",
              wordWrap: "normal",
              fontWeight: 600,
            }}
            className="text-center text-black mt-4"
          >
            {cekilisSiteAltMetin}
          </div>
          <div className="text-center mt-5 d-flex flex-row justify-content-center">
            <LocalizedLink
              to="/cekilis"
              role="button"
              className="shadow text-light btn btn-primary  d-flex justify-content-center align-items-center"
              style={{ borderRadius: 7, height: 50, width: 200 }}
            >
              {hemenDene}
            </LocalizedLink>
          </div>

          <Row className="mt-5">
            <Col lg="6" className="pr-md-5">
              <div className={styles.featureContainer}>
                <div className={styles.feature}>
                  <div className={styles.featureIcon}>
                    <span>
                      <FontAwesomeIcon icon={faMoneyBill}></FontAwesomeIcon>
                    </span>
                  </div>
                  <div className={styles.featureDetail}>
                    <div style={{ fontSize: "22px", fontWeight: "bold" }}>
                      {ucretsizCekilisYapin}
                    </div>
                    <span className="text-black">
                      {ucretsizCekilisYapinMetin}
                    </span>
                  </div>
                </div>
              </div>

              <div className={`${styles.featureContainer} mt-5`}>
                <div className={styles.feature}>
                  <div className={styles.featureIcon}>
                    <span>
                      <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>
                    </span>
                  </div>
                  <div className={styles.featureDetail}>
                    <div style={{ fontSize: "22px", fontWeight: "bold" }}>
                      {kaydedinVePaylasin}
                    </div>
                    <span className="text-black">
                      {kaydedinVePaylasinMetin}
                    </span>
                  </div>
                </div>
              </div>

              <div className={`${styles.featureContainer} mt-5`}>
                <div className={styles.feature}>
                  <div className={styles.featureIcon}>
                    <span>
                      <FontAwesomeIcon icon={faLightbulb}></FontAwesomeIcon>
                    </span>
                  </div>
                  <div className={styles.featureDetail}>
                    <div style={{ fontSize: "22px", fontWeight: "bold" }}>
                      {deneyimleyin}
                    </div>
                    <span className="text-black">{deneyimleyinMetin}</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="6" className="">
              <img src={svgCekilis} alt={cekilisSitesi} />
            </Col>
          </Row>
        </Section>
        <Section containerStyle={{ paddingBottom: 30 }}>
          <div className="text-center d-flex flex-column flex-lg-row justify-content-center align-items-center">
            <div
              className={`d-inline-block pr-3 mb-5 mx-2 ${styles.productHeader}`}
              style={{
                wordWrap: "normal",
                fontWeight: 700,
                letterSpacing: "-0.049rem",
                lineHeight: "3.9rem",
              }}
            >
              {sizeOzelProjeniziKonusalim}
            </div>
            <LocalizedLink
              to="/iletisim/"
              role="button"
              className="shadow btn btn-primary d-flex justify-content-center align-items-center"
              style={{
                borderRadius: 7,
                marginTop: "-30px",
                height: 50,
                width: 200,
              }}
            >
              <strong>{iletisimeGec}</strong>
            </LocalizedLink>
          </div>
        </Section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    kutuphaneBilgisayar: file(
      relativePath: { eq: "homepage/kutuphane-bilgisayar.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    referans1: file(relativePath: { eq: "homepage/referans_1.png" }) {
      childImageSharp {
        fixed(width: 300, quality: 100, grayscale: true) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    referans2: file(relativePath: { eq: "homepage/referans_2.png" }) {
      childImageSharp {
        fixed(width: 300, quality: 100, grayscale: true) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    referans3: file(relativePath: { eq: "homepage/referans_3.png" }) {
      childImageSharp {
        fixed(width: 300, quality: 100, grayscale: true) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

Home.propTypes = {
  data: PropTypes.object,
}
